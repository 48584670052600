
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  VModel,
  Vue,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Upgrade from "@/views/Upgrade.vue";
import Item from "@/components/items/Item.vue";
import LastUpgrade from "@/components/upgrade/LastUpgrade.vue";
import BottomSheet from "@/components/base/BottomSheet.vue";

@Component({
  components: { BottomSheet, LastUpgrade, Item },
})
export default class LastUpgrades extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() upgrades!: Upgrade[] | null;
}
