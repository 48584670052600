
import {
  Component,
  Inject,
  InjectReactive,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import Heading from "@/components/base/Heading.vue";
import Price from "@/components/base/Price.vue";
import Item from "@/components/items/Item.vue";
import ItemList from "@/components/items/ItemList.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import BorderContainer from "@/components/base/BorderContainer.vue";
import UpgradeArc from "@/components/upgrade/UpgradeArc.vue";
import BalanceInput from "@/components/base/BalanceInput.vue";
import { PaginateUserItemWithoutUserQuery } from "@/graphql/queries";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import defaultSteamItemImage from "@/assets/default-steam-item.png";
import gql from "graphql-tag";
import {
  AuthUserFragment,
  UpgradeFragment,
  UserItemWithoutUserFragment,
} from "@/graphql/fragments";
import TopAppBar from "@/components/base/TopAppBar.vue";
import { useScroll } from "@/graphql/use-scroll";
import { useFindAll } from "@/graphql/use-find-all";
import { useFindOne } from "@/graphql/use-find-one";
import { mongoTextSearch } from "@/helpers/mongo-text-search.helper";
import LastUpgrades from "@/components/upgrade/LastUpgrades.vue";

const MAX_UPGRADE_PROBABILITY = 0.67; // 1.5x
const MIN_UPGRADE_PROBABILITY = 0.1; // x100

@Component({
  metaInfo: {
    title: "Апгрейд",
  },
  apollo: {
    lastUpgrades: {
      update(data) {
        return data.lastUpgrades || null;
      },
      query: gql`
        query {
          lastUpgrades {
            ...Upgrade
          }
        }
        ${UpgradeFragment}
      `,
    },
    items1: {
      update(data) {
        return data.scrollUserItem
          ? data.scrollUserItem.docs.map((item) => new UserItemEntity(item))
          : null;
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.user;
      },
      query: useScroll("UserItem", UserItemWithoutUserFragment),
      debounce: 300,
      variables() {
        return {
          options: {
            sort: this.sortItems1,
          },
          filter: {
            ...(this.searchItems1
              ? { _text: { search: mongoTextSearch(this.searchItems1) } }
              : {}),
            userId: { eq: this.user?._id },
            state: { eq: "DEFAULT" },
          },
        };
      },
    },
    items2: {
      update(data) {
        return data.findAllUserItem
          ? data.findAllUserItem.map((item) => new UserItemEntity(item))
          : [];
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.user || !this.betAmount;
      },
      debounce: 300,
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      variables() {
        return {
          options: {
            sort: this.sortItems2,
          },
          filter: {
            ...(this.searchItems2
              ? { _text: { search: mongoTextSearch(this.searchItems2) } }
              : {}),
            price: {
              gte: this.items2PriceRange[0],
              lte: this.items2PriceRange[1],
            },
            state: { eq: "DEFAULT" },
            userId: { exists: false },
            isNameFirstUnique: { eq: true },
          },
        };
      },
    },
    loadedUpgradeGame: {
      update(data) {
        return data.findOneUpgrade;
      },
      query: useFindOne("Upgrade", UpgradeFragment),
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          filter: {
            _id: { eq: this.$route.params.id },
          },
        };
      },
    },
    loadedUpgradeGameItems: {
      update(data) {
        return (data.findAllUserItem || [])
          .sort((a, b) => {
            if (a._id === this.loadedUpgradeGame.betItemId) {
              return -1;
            } else if (b._id === this.loadedUpgradeGame.betItemId) {
              return 1;
            }

            return 0;
          })
          .map((item, index, arr) => {
            item = new UserItemEntity(item);

            if (arr.length === 1) {
              this.bet = this.loadedUpgradeGame.betAmount;
            }

            if (item._id !== this.loadedUpgradeGame.betItemId) {
              item.price = Number(
                (
                  this.loadedUpgradeGame.betAmount /
                  this.loadedUpgradeGame.probability
                ).toFixed(2)
              );

              this.$nextTick(() => (this.selectedItem = item));
            } else if (this.loadedUpgradeGame.betItemId) {
              this.bet = item;
            }

            return item;
          });
      },
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      skip() {
        return !this.loadedUpgradeGame;
      },
      variables() {
        return {
          filter: {
            _id: {
              in: [
                ...(this.loadedUpgradeGame.betItemId
                  ? [this.loadedUpgradeGame.betItemId]
                  : []),
                this.loadedUpgradeGame.desiredItemId ||
                  this.loadedUpgradeGame.profitItemId,
              ],
            },
          },
        };
      },
    },
  },
  components: {
    LastUpgrades,
    TopAppBar,
    Price,
    Item,
    BalanceInput,
    BorderContainer,
    ItemList,
    ScrollFetchMore,
    GradientBtn,
    UpgradeArc,
    Heading,
  },
})
export default class Upgrade extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Ref("arc") readonly arc!: UpgradeArc;
  private defaultSteamItemImage = defaultSteamItemImage;
  private lastUpgrades: Upgrade[] | null = null;
  private showLastUpgradeDialog = false;
  private showConfirmDialog = false;

  private loadedUpgradeGame: any = null;
  private loadedUpgradeGameItems: UserItemEntity[] = [];
  private sortItems1 = "-price";
  private sortItems2 = "-price";
  private searchItems1 = "";
  private searchItems2 = "";
  private items1: UserItemEntity[] | null = null;
  private items2: UserItemEntity[] = [];
  private upgrading = false;
  private bet: UserItemEntity | number | null = null;
  private selectedItem: UserItemEntity | null = null;

  @Watch("sortItems1")
  @Watch("searchItems1")
  onVariablesChanged1() {
    const scroll = this.$el.querySelector("#upgrade-left-scroll");
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }

  @Watch("sortItems2")
  @Watch("searchItems2")
  onVariablesChanged2() {
    const scroll = this.$el.querySelector("#upgrade-right-scroll");
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }

  get itemAspectRatio() {
    return this.$vuetify.breakpoint.mdAndUp ? 354 / 228 : 1.2 / 1;
  }

  @Watch("betAmount")
  betAmountChanged(value) {
    if (!value) {
      this.items2 = [];
      this.selectedItem = null;
    } else if (
      value > this.items2PriceRange[1] ||
      value < this.items2PriceRange[0]
    ) {
      this.selectedItem = null;
    }
  }

  get items2PriceRange() {
    return [
      this.betAmount / MAX_UPGRADE_PROBABILITY,
      this.betAmount / MIN_UPGRADE_PROBABILITY,
    ];
  }

  get odds() {
    return this.selectedItem
      ? Number((this.betAmount / this.selectedItem.price).toFixed(2))
      : 0;
  }

  get betAmount() {
    let betAmount = 0;

    if (this.bet instanceof UserItemEntity) {
      betAmount = this.bet.price;
    } else if (typeof this.bet === "number") {
      betAmount = this.bet;
    }

    return betAmount;
  }

  get isUserSkipUpgradeConfirmation() {
    if (!this.user) {
      return false;
    }

    return this.user.skipUpgradeConfirmation;
  }

  async setUserSkipUpgradeConfirmation(skipUpgradeConfirmation: boolean) {
    await this.$apollo.mutate({
      mutation: gql`
        mutation ($input: UpdateUserInput!) {
          updateUser(input: $input) {
            ...AuthUser
          }
        }
        ${AuthUserFragment}
      `,
      variables: {
        input: {
          skipUpgradeConfirmation,
        },
      },
    });
  }

  async confirmUpgrade() {
    if (this.user && this.isUserSkipUpgradeConfirmation) {
      return await this.upgrade();
    }

    this.showConfirmDialog = true;
  }

  get isBetUserItem() {
    return this.bet instanceof UserItemEntity;
  }

  set isBetUserItem(value) {
    this.bet = null;
  }

  async skip() {
    this.arc.skip();
  }

  async upgrade() {
    this.upgrading = true;
    this.showConfirmDialog = false;

    const upgrade = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: PlayUpgradeInput!) {
            playUpgrade(input: $input) {
              _id
              betAmount
              profitAmount
              betItem {
                ...UserItemWithoutUser
              }
              profitItem {
                ...UserItemWithoutUser
              }
            }
          }
          ${UserItemWithoutUserFragment}
        `,
        variables: {
          input: {
            betAmount: this.betAmount,
            profitItemName: this.selectedItem!.name,
            ...(this.isBetUserItem
              ? { betItemId: (this.bet as UserItemEntity)._id }
              : {}),
          },
        },
      })
      .then(({ data: { playUpgrade: upgrade } }) => upgrade)
      .catch((e) => {
        console.log(e);
      });

    if (!upgrade) {
      this.$notify({
        type: "info",
        text: `К сожалению, данный предмет уже не доступен для апгрейда`,
      });
      return this.reset();
    }

    //this.items1?.splice(this.items1.findIndex(({_id}) => _id = upgrade.betItem._id), 1);

    await this.arc.animate(!!upgrade.profitAmount, upgrade._id);
    const oldBet = this.bet;
    await this.$apollo.queries.items1.refetch();

    if (upgrade.profitAmount) {
      if (upgrade.betItem) {
        this.bet = new UserItemEntity(upgrade.profitItem);
      }
      this.selectedItem = null;
      this.$notify({
        text: `Поздравляем, вы выиграли предмет за ${upgrade.profitItem.price} руб. Он добавлен в Ваш инвентарь.`,
        title: upgrade.profitItem.name,
        data: {
          class: `notification-${upgrade.profitItem.rarity}`,
          image: upgrade.profitItem.image,
        },
      });
    } else {
      if (upgrade.betItem) {
        this.bet = null;
      }
    }

    if (upgrade.profitAmount || upgrade.betItem) {
      await this.arc.resetAnimate();
    }

    if (!this.isBetUserItem && this.bet! > this.user!.balance) {
      this.bet = this.user!.balance;
    }

    if (oldBet === this.bet) {
      await this.$apollo.queries.items2.refetch();
    }
    this.upgrading = false;
  }

  async repeatUpgrade() {
    this.upgrading = true;

    await this.arc.animate(
      !!this.loadedUpgradeGame.profitAmount,
      this.loadedUpgradeGame._id
    );

    this.upgrading = false;
  }

  reset() {
    this.upgrading = false;
    this.selectedItem = null;
    this.bet = null;
    Object.keys(this.$apollo.queries).forEach((key) =>
      this.$apollo.queries[key].refetch()
    );
  }
}
