
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue,
  Watch,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

@Component({})
export default class BalanceInput extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private focused = false;
  private inputValue = "";
  @Prop({ default: true }) icon!: boolean;
  @Prop() value!: number;
  @Prop({ default: 0 }) min!: number;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;

  get textFieldValue() {
    return String(
      this.focused
        ? this._value === 0
          ? ""
          : this._value
        : this._value.toFixed(2)
    );
  }

  get inputWidth() {
    const numbersCount = this.inputValue.length - 1;

    if (this.isMobile) {
      return 142;
    }
    return 160 + (numbersCount > 3 ? (numbersCount - 3) * 8 : 0);
  }

  get max() {
    return this.user ? this.user.balance! : 0;
  }

  set _value(value) {
    this.$emit("input", value);
  }

  get _value() {
    return this.value || 0;
  }

  multiply(value: number) {
    if (value <= 1 && this._value === 0) {
      return;
    }
    this.setValue(this._value === 0 ? this.min || 1 : this._value * value);
  }

  @Watch("value")
  async setValue(value: string | number) {
    let _value = Number(Number(value).toFixed(2));

    if (
      Number.isNaN(_value) ||
      (this.user && this.user.balance < this.min && !_value)
    ) {
      _value = 0;
    } else if (_value < this.min) {
      _value = this.min;
    } else if (_value > this.max) {
      _value = this.max;
    }

    this._value = _value;
    await this.$nextTick();
    this.inputValue = this.textFieldValue;
  }
}
