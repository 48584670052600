var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-text-field',_vm._b({style:({
    width: `${_vm.inputWidth}px`,
  }),attrs:{"id":"balance-input","placeholder":"0,00","rounded":"","outlined":"","dense":"","autocomplete":"off","type":"number","disabled":!_vm.user || _vm.user.balance < _vm.min || _vm.user.balance === 0,"value":_vm.inputValue,"hide-details":""},on:{"focus":() => {
      _vm.focused = true;
      _vm.inputValue = _vm.textFieldValue;
    },"blur":() => {
      _vm.focused = false;
      _vm.inputValue = _vm.textFieldValue;
    },"change":_vm.setValue},scopedSlots:_vm._u([(_vm.icon)?{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center pt-1"},[_vm._v("Р")])]},proxy:true}:null,{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mt-1",attrs:{"icon":"","rounded":"","x-small":"","disabled":!_vm.user || _vm.user.balance < _vm.min || _vm.user.balance === 0},on:{"click":function($event){return _vm.multiply(2)}}},[_c('v-icon',[_vm._v(" fas fa-plus")])],1)]},proxy:true},{key:"prepend",fn:function(){return [_c('v-btn',{staticClass:"mt-1",attrs:{"disabled":!_vm.user || _vm.user.balance < _vm.min || _vm.user.balance === 0,"icon":"","rounded":"","x-small":""},on:{"click":function($event){return _vm.multiply(0.5)}}},[_c('v-icon',[_vm._v(" fas fa-minus")])],1)]},proxy:true}],null,true)},'v-text-field',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }