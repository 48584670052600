
import { SVG } from "@svgdotjs/svg.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import anime from "animejs";
import Chance from "chance";
import AnimatedNumber from "@/components/base/AnimatedNumber.vue";

interface IUpgradeArc {
  animate(win: boolean, seed: string | undefined, duration: number): void;

  skip(): void;
}

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class UpgradeArc extends Vue implements IUpgradeArc {
  @Prop({ default: 209, type: Number }) size!: number;
  @Prop({ default: 0.33, type: Number }) odds!: number;
  @Prop({ default: 3, type: Number }) strokeWidth!: number;
  @Prop({ default: 220, type: Number }) visibleDegree!: number;
  animation: any = null;
  mainAnimation: any = null;
  lastChanceAngle: any = null;
  chanceDuration = 0;

  mounted() {
    this.draw();
  }

  get chance() {
    return Math.floor(this.odds * 100);
  }

  get radius() {
    return this.size / 2 - this.strokeWidth / 2;
  }

  get center() {
    return this.size / 2;
  }

  draw() {
    let svg1 = this.$el.querySelector(".wheel > svg");
    let svg2 = this.$el.querySelector(".wheel-animate svg");

    if (svg1) {
      svg1.remove();
    }
    if (svg2) {
      svg2.remove();
    }

    let container1 = this.$el.querySelector(".wheel") as HTMLElement;
    let container2 = this.$el.querySelector(".wheel-animate") as HTMLElement;
    let draw1 = SVG().addTo(container1).size("100%", "100%");
    let draw2 = SVG().addTo(container2).size("100%", "100%");

    const circle = draw1
      .path(this.getPathDStringLine(this.visibleDegree))
      .attr({
        stroke: draw1
          .gradient("linear", function (add) {
            add.stop(0, "#BCB3FF");
            add.stop(0.583663, "#BCB3FF", 0);
          })
          .from(this.center, 0)
          .to(this.center, this.size),
      })
      .stroke({
        width: this.strokeWidth,
        opacity: 0.2,
      })
      .rotate(-this.visibleDegree / 2, this.center, this.center);

    this.lastChanceAngle = this.visibleDegree * this.odds;

    const line = draw2
      .path(this.getPathDStringLine(this.lastChanceAngle))
      .attr({
        stroke: "#FF822B",
        id: "upgrade-arc-line",
      })
      .stroke({
        width: this.strokeWidth,
      })
      .rotate(-this.visibleDegree / 2, this.center, this.center);

    const gradient = draw2
      .path(this.getPathDStringGradient(this.lastChanceAngle))
      .fill(
        draw2
          .gradient("radial", function (add) {
            add.stop(0.74, "#FF1F00", 0);
            add.stop(1, "#FFB800", 1);
          })
          .attr({
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "translate(104.5 104.5) rotate(90) scale(104.5)",
            cx: "0",
            cy: "0",
          })
          .radius(1)
      )
      .opacity(0.2)
      .attr({
        id: "upgrade-arc-gradient",
      })
      .rotate(-this.visibleDegree / 2, this.center, this.center);
  }

  getPathDStringGradient(angle: number, offset = 25, _center?: number) {
    const center = _center || this.center,
      radius2 = center - offset,
      radius1 = center,
      sliceAngle = angle,
      sliceAngleInRadians = ((sliceAngle - 90) * Math.PI) / 180,
      x1 = center + radius1 * Math.cos(sliceAngleInRadians),
      x2 = center + radius2 * Math.cos(sliceAngleInRadians),
      y1 = center + radius1 * Math.sin(sliceAngleInRadians),
      y2 = center + radius2 * Math.sin(sliceAngleInRadians);
    return `M${center},0 A ${radius1},${radius1} 0 ${
      angle < 180 ? "0" : "1"
    }, 1 ${x1},${y1} L${x2},${y2} A ${radius2},${radius2} 0 0, 0 ${center},${offset} Z`;
  }

  getPathDStringLine(angle: number, _radius?: number, _center?: number) {
    const center = _center || this.center,
      radius = _radius || this.radius,
      sliceAngle = angle,
      sliceAngleInRadians = ((sliceAngle - 90) * Math.PI) / 180,
      x = center + radius * Math.cos(sliceAngleInRadians),
      y = center + radius * Math.sin(sliceAngleInRadians);

    return `M${center},${center - radius} A${radius} ${radius} 0 ${
      angle < 180 ? "0" : "1"
    } 1 ${x} ${y} A${radius} ${radius} 0 ${angle < 180 ? "0" : "1"} ${
      angle < 0 ? "1" : "0"
    }  ${center} ${center - radius} Z`;
  }

  public async skip() {
    if (this.mainAnimation) {
      this.mainAnimation.seek(this.mainAnimation.duration);
    }
  }

  public animate(
    win = true,
    seed: string | undefined = undefined,
    duration = 10000
  ) {
    const chance = new Chance(seed);
    const lineAngle = this.visibleDegree * this.odds;
    const lineAngleHalf = lineAngle / 2;
    const rotateCenter = this.visibleDegree / 2 - lineAngleHalf;
    const [rightEdgeAngle, leftEdgeAngle] = [
      rotateCenter - lineAngleHalf,
      rotateCenter + lineAngleHalf,
    ];
    const angle = win
      ? chance.integer({ min: rightEdgeAngle, max: leftEdgeAngle })
      : chance.bool()
      ? chance.integer({
          min: leftEdgeAngle + 3,
          max: leftEdgeAngle + this.visibleDegree / 2.5,
        })
      : chance.integer({
          max: rightEdgeAngle - 3,
          min: rightEdgeAngle - this.visibleDegree / 2.5,
        });
    const target = this.$el.querySelector(".wheel-animate");

    this.mainAnimation = anime({
      targets: target,
      duration,
      easing: "easeOutSine",
      rotate: 360 * chance.integer({ min: 5, max: 7 }) + angle,
      complete: () => {
        anime({
          targets: target,
          duration: 0,
          rotate: angle,
        });
      },
    });

    return this.mainAnimation.finished;
  }

  public resetAnimate(duration = this.chanceDuration) {
    const target = this.$el.querySelector(".wheel-animate");

    const animation = anime({
      targets: target,
      duration: duration || 0,
      easing: "easeOutSine",
      rotate: 0,
    });

    return animation.finished;
  }

  @Watch("odds")
  animateChance(chance: number, oldChance: number) {
    if (typeof chance !== "number") {
      return;
    }

    if (this.animation) {
      this.animation.pause();
    }

    let obj = {
      angle: this.lastChanceAngle,
    };

    let line = this.$el.querySelector("#upgrade-arc-line")!;
    let gradient = this.$el.querySelector("#upgrade-arc-gradient")!;

    const angle = chance * this.visibleDegree;
    this.chanceDuration =
      (Math.abs(this.lastChanceAngle - angle) / this.visibleDegree) * 1000;

    this.animation = anime({
      targets: obj,
      angle,
      duration: this.chanceDuration,
      easing: "linear",
      complete: () => {
        this.lastChanceAngle = angle;

        line.setAttribute("d", this.getPathDStringLine(angle));

        gradient.setAttribute("d", this.getPathDStringGradient(angle));
      },
      update: () => {
        if (obj.angle <= this.visibleDegree) {
          this.lastChanceAngle = obj.angle;

          line.setAttribute("d", this.getPathDStringLine(obj.angle));
          gradient.setAttribute("d", this.getPathDStringGradient(obj.angle));
        }
      },
    });
  }
}
