
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  VModel,
  Vue,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import defaultSteamItemImage from "@/assets/default-steam-item.png";
import { UserItemEntity } from "@/entities/user-item.entity";
import Item from "@/components/items/Item.vue";
import { useFindOne } from "@/graphql/use-find-one";
import {
  UpgradeFragment,
  UserItemWithoutUserFragment,
} from "@/graphql/fragments";
import { useFindAll } from "@/graphql/use-find-all";
import GradientBtn from "@/components/base/GradientBtn.vue";
import BalanceInput from "@/components/base/BalanceInput.vue";
import Price from "@/components/base/Price.vue";
import BorderContainer from "@/components/base/BorderContainer.vue";

@Component({
  components: { BorderContainer, Price, BalanceInput, GradientBtn, Item },
  apollo: {
    loadedUpgradeGame: {
      update(data) {
        return data.findOneUpgrade;
      },
      query: useFindOne("Upgrade", UpgradeFragment),
      skip() {
        return !this.upgrade;
      },
      variables() {
        return {
          filter: {
            _id: { eq: this.upgrade._id },
          },
        };
      },
    },
    loadedUpgradeGameItems: {
      update(data) {
        return (data.findAllUserItem || [])
          .sort((a, b) => {
            if (a._id === this.loadedUpgradeGame.betItemId) {
              return -1;
            } else if (b._id === this.loadedUpgradeGame.betItemId) {
              return 1;
            }

            return 0;
          })
          .map((item, index, arr) => {
            item = new UserItemEntity(item);

            if (arr.length === 1) {
              this.bet = this.loadedUpgradeGame.betAmount;
            }

            if (item._id !== this.loadedUpgradeGame.betItemId) {
              item.price = Number(
                (
                  this.loadedUpgradeGame.betAmount /
                  this.loadedUpgradeGame.probability
                ).toFixed(2)
              );

              this.$nextTick(() => (this.selectedItem = item));
            } else if (this.loadedUpgradeGame.betItemId) {
              this.bet = item;
            }

            return item;
          });
      },
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      skip() {
        return !this.loadedUpgradeGame;
      },
      variables() {
        return {
          filter: {
            _id: {
              in: [
                ...(this.loadedUpgradeGame.betItemId
                  ? [this.loadedUpgradeGame.betItemId]
                  : []),
                this.loadedUpgradeGame.desiredItemId ||
                  this.loadedUpgradeGame.profitItemId,
              ],
            },
          },
        };
      },
    },
  },
})
export default class LastUpgrades extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() upgrade!: any | null;
  private loadedUpgradeGame: any = null;
  private loadedUpgradeGameItems: UserItemEntity[] = [];
  private selectedItem: UserItemEntity | null = null;
  private bet: UserItemEntity | number | null = null;
  private defaultSteamItemImage = defaultSteamItemImage;

  get isUserWin() {
    if (this.upgrade?.profitItemId) {
      return true;
    }

    return false;
  }

  get isBetUserItem() {
    return this.bet instanceof UserItemEntity;
  }

  set isBetUserItem(value) {
    this.bet = null;
  }
}
